<template>
  <section class="invoice-add-wrapper add-ticket-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <validation-observer ref="rules">
          <b-form
            ref="form"
            @submit.prevent
          >
            <b-card
              no-body
              class="invoice-preview-card py-3"
            >
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>
                  <b-col
                    v-if="user.userRole === 'Admin'"
                    cols="12"
                  >
                    <b-form-group
                      label="Send this Ticket to:"
                      label-for="user"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="User"
                        rules="required"
                      >
                        <v-select
                          v-if="allUsers"
                          id="user"
                          v-model="ticketDestinationName"
                          :options="allUsers"
                          label="email"
                          :clearable="false"
                          class="mb-2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Ticket Subject"
                      label-for="subject"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Subject"
                        rules="required"
                      >
                        <b-form-input
                          id="subject"
                          v-model="ticket.ticket_subject"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter a Subject for your support ticket"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Ticket Category:"
                      label-for="categ"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Category"
                        rules="required"
                      >
                        <v-select
                          id="categ"
                          v-model="ticket.ticket_type"
                          :options="ticketTypes"
                          :clearable="false"
                          class="mb-2"
                          @input="UserUrgentNotification"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label>Add an image/file (optional - e.g. screenshot)
                        <router-link
                          v-b-tooltip.hover.focus.v-primary
                          :to="''"
                          :class="'bi bi-info-circle'"
                          :title="'Accepted formats: zip, txt, html, htm, png, jpg, bmp, ico, gif, xlsx, csv, pdf'"
                        />
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="File"
                        rules="mimes:zip,text/plain,html,htm,image/png,image/jpeg,image/bmp,image/ico,image/gif,xlsx,csv,pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,text/csv"
                      >
                        <b-form-file
                          id="file"
                          ref="file"
                          v-model="ticket.ticket_files"
                          accept=".zip,.txt,.html,.htm,.png,.jpg,.bmp,.ico,.gif,.xlsx,.csv,.pdf"
                          :state="errors.length > 0 ? false:null"
                          multiple
                          plain
                          style="display:none"
                          @input="onChange"
                        /><br>
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="browse"
                        >
                          Browse...
                        </b-button>
                        <small
                          v-if="filesAccumulated.length===0"
                        >&nbsp;No file/s selected.</small>
                        <small class="text-danger">&nbsp;{{ errors[0] }}</small>
                      </validation-provider>
                      <div
                        v-for="(thisFile,index) in filesAccumulated"
                        :key="index"
                      >
                        <file-attachment
                          :filename="thisFile.name"
                          :index="index"
                          @removeItemFromArray="removeItemAttachment"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Problem Description"
                      label-for="description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <quill-editor
                          v-if="user.userRole === 'Admin'"
                          v-model="ticket.ticket_text"
                          :options="snowOption"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Describe the problem that you have"
                        />
                        <b-form-textarea
                          v-else
                          id="description"
                          v-model="ticket.ticket_text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Describe the problem that you have"
                          rows="10"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="submitHandler"
          >
            Send Ticket
          </b-button>
        </b-card>

      </b-col>
    </b-row>

    <b-modal
        id="urgent-notification"
        title="Urgent Category"
        no-close-on-backdrop
        hide-header-close
        ok-title="Continue"
        size="lg"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">
          Please use the <strong>"Urgent"</strong> tag only for issues that are truly urgent and affect the normal functionality of your platform.<br><br>
          If an issue is not genuinely urgent, <strong>a penalty of 100 euros</strong> may be added to your next invoice for the technical intervention, especially if it is done outside our office hours.<br>
          Thank you!
        </h5>
      </b-modal>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  VBToggle, BFormFile, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/libs/axios' // eslint-disable-line
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import { quillEditor } from 'vue-quill-editor'
import FileAttachment from './FileAttachment.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    FileAttachment,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      ticketTypes: [{ code: '', label: 'Loading...' }],
      allUsers: [{ id: 0, email: 'Loading users' }],
      ticket: {
        ticket_subject: '',
        ticket_text: '',
        ticket_type: 'Select a category',
        destination: '',
        ticket_files: [],
      },
      filesAccumulated: [],
      sending: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    ticketDestinationName: {
      get() {
        if (this.ticket.destination === '' || !this.ticket?.destination) {
          return 'Select an user'
        }
        return `${this.ticket.destination.email} - ${this.ticket.destination.domain ?? 'N/A'}`
      },
      set(value) {
        this.ticket.destination = value
      },
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    if (this.user.isAdmin) {
      this.$store.dispatch('users/fetchAllUsers')
        .then(() => {
          this.allUsers = this.$store.state.users.allUsers
          if (this.$route.params.id) {
            this.ticket.destination = this.allUsers.find(user => Number(user.user_id) === Number(this.$route.params.id))
          }
        })
    }
    this.$store.dispatch('tickets/fetchTicketTypes').then(() => {
      this.ticketTypes = this.$store.state.tickets.ticketTypes
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    UserUrgentNotification() {
      if (!this.user.isAdmin && this.ticket.ticket_type.code === 'urgent') {
        this.$bvModal.show('urgent-notification')
      }
    },
    checkCategory() {
      if (typeof this.ticket.ticket_type === 'string') this.ticket.ticket_type = null
      else if (typeof this.ticket.ticket_type === 'object' && this.ticket.ticket_type.code === 'all') this.ticket.ticket_type = null
    },
    async submitHandler() {
      this.checkCategory()
      await this.$refs.rules.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('ticket_subject', this.ticket.ticket_subject)
          formData.append('ticket_text', this.ticket.ticket_text)
          formData.append('ticket_type', this.ticket.ticket_type.code)
          formData.append('destination', this.ticket.destination.uid)

          const i = this.filesAccumulated.length
          for (let x = 0; x < i; x += 1) {
            formData.append('ticket_file[]', this.filesAccumulated[x])
          }

          const headers = { 'Content-Type': 'multipart/form-data' }
          this.sending = true
          const newTicket = async () => {
            const res = await axios.post('/api/tickets/create-new-ticket', formData, { headers })
              .then(() => {
                console.log(res)
                this.$store.state.tickets.openTickets = null
                this.$router.push('/tickets/open-tickets')
              })
              .catch(error => { console.log(error) })
          }
          newTicket()
        }
      })
    },
    onChange() {
      this.ticket.ticket_files.forEach(thisFile => {
        this.filesAccumulated.push(thisFile)
      })
    },
    removeItemAttachment(index) {
      this.filesAccumulated.splice(index, 1)
    },
    browse() {
      this.$refs.file.$el.click()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.add-ticket-wrapper .ql-editor {
  min-height: 320px;
}
.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.file-Attachment span{
  display: none;
}
</style>
